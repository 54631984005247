// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background: #001f3f;
  color: #fff;
  text-align: center;
  padding: 1rem;
  position: fixed;
  width: 100%;
  bottom: 0;
`;

const Footer = () => (
  <FooterWrapper>
    <p>&copy; 2024 CajCode LLC. All rights reserved.</p>
  </FooterWrapper>
);

export default Footer;
