import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { Box, Typography, Button as MuiButton } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple'; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import femFitImage from './images/femFit.png';
import hairShop1 from './images/hairShop1.png';
import hairShop2 from './images/hairShop2.png';
import hairShop3 from './images/hairShop3.png';
import hairShop4 from './images/hairShop4.png';
import fratSwipe1 from './images/fratSwipe1.png';
import fratSwipe2 from './images/fratSwipe2.png';
import fratSwipe3 from './images/fratSwipe3.png';
import gradJump1 from './images/gradJump1.png';
import gradJump2 from './images/gradJump2.png';

const hairShopImages = [hairShop1, hairShop2, hairShop3, hairShop4];
const fratSwipeImages = [fratSwipe1, fratSwipe2, fratSwipe3];
const gradJumpImages = [gradJump1, gradJump2];

const AppShowcaseWrapper = styled.section`
  padding: 80px 20px;
  background: linear-gradient(135deg, #cdeaff, #a0e1ff);
  text-align: center;
  margin-top: 60px;
  color: #001f3f;

  @media (max-width: 768px) {
    padding: 40px 10px;
  }
`;

const ShowcaseTitle = styled.h2`
  font-size: 3em;
  margin-bottom: 50px;
  color: #0059b2;

  @media (max-width: 768px) {
    font-size: 2.5em;
    margin-bottom: 30px;
  }
`;

const SliderContainer = styled.div`
  width: 80%;
  margin: 0 auto 60px;

  .slick-prev, .slick-next {
    color: #0059b2;
    font-size: 2em;
    z-index: 1;
  }

  .slick-prev:before, .slick-next:before {
    color: #0059b2;
  }

  .slick-slide img {
    margin: 0 auto;
    max-height: 550px;
    border-radius: 15px;
    object-fit: contain;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }

  h3 {
    margin-bottom: 20px;
    font-size: 2em;
    color: #0059b2;

    @media (max-width: 768px) {
      font-size: 1.6em;
      margin-bottom: 15px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto 40px;
  }
`;

const StyledMuiButton = styled(MuiButton)`
  background-color: #0059b2 !important;
  color: #fff !important;
  padding: 12px 24px !important;
  font-size: 1.1em !important;
  border-radius: 6px !important;
  text-transform: none !important;

  &:hover {
    background-color: #00408a !important;
  }
`;

const StyledTypography = styled(Typography)`
  font-weight: bold !important;
  color: #0059b2 !important;
`;

const AppLink = styled.a`
  text-decoration: none;
  display: inline-block;
  margin-top: 25px;
`;

const AppShowcase = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    fade: false,
    arrows: true, // Include arrows
    prevArrow: <div className="slick-prev">‹</div>,
    nextArrow: <div className="slick-next">›</div>,
  };

  return (
    <AppShowcaseWrapper>
      <ShowcaseTitle>Applications</ShowcaseTitle>
      <SliderContainer>
        <h3>FratSwipe</h3>
        <Slider {...settings}>
          {fratSwipeImages.map((img, idx) => (
            <div key={idx}>
              <img src={img} alt={`FratSwipe Screenshot ${idx + 1}`} />
            </div>
          ))}
        </Slider>
      </SliderContainer>

      <Box id="get-started" my={4} textAlign="center">
        <StyledTypography variant="h4" gutterBottom>
          Get Started with FratSwipe
        </StyledTypography>
        <Typography variant="h6" paragraph>
          Download FratSwipe today and streamline your fraternity and sorority recruitment!
        </Typography>
        <StyledMuiButton
          startIcon={<AppleIcon />}
          href="https://apps.apple.com/us/app/fratswipe/id6520395751"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download on the App Store
        </StyledMuiButton>
      </Box>

      <SliderContainer>
        <h3>GradJump</h3>
        <Slider {...settings}>
          {gradJumpImages.map((img, idx) => (
            <div key={idx}>
              <img src={img} alt={`GradJump Screenshot ${idx + 1}`} />
            </div>
          ))}
        </Slider>
        <AppLink href="https://gradjump.com" target="_blank" rel="noopener noreferrer">
          <StyledMuiButton>Visit GradJump</StyledMuiButton>
        </AppLink>
      </SliderContainer>

      <SliderContainer>
        <h3>FemFitNow</h3>
        <Slider {...settings}>
          <div>
            <img src={femFitImage} alt="FemFitNow Screenshot" />
          </div>
        </Slider>
        <AppLink href="https://femfitnow.com" target="_blank" rel="noopener noreferrer">
          <StyledMuiButton>Visit FemFitNow</StyledMuiButton>
        </AppLink>
      </SliderContainer>

      <SliderContainer>
        <h3>HairShop</h3>
        <Slider {...settings}>
          {hairShopImages.map((img, idx) => (
            <div key={idx}>
              <img src={img} alt={`HairShop Screenshot ${idx + 1}`} />
            </div>
          ))}
        </Slider>
      </SliderContainer>
    </AppShowcaseWrapper>
  );
};

export default AppShowcase;
