// src/components/Hero.js
import React from 'react';
import styled from 'styled-components';

const HeroWrapper = styled.section`
  background: linear-gradient(135deg, #001f3f, #007bff);
  color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 1rem;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
`;

const Title = styled.h1`
  font-size: 3rem;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 1rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 2rem;
  max-width: 800px;
  line-height: 1.5;
`;

const Hero = () => (
  <HeroWrapper>
    <Title>Solving Real-World Problems</Title>
    <Subtitle>With Innovative Software Solutions</Subtitle>
  </HeroWrapper>
);

export default Hero;
