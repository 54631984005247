import React from 'react';
import styled from 'styled-components';
import cajCodeLogo from '../assets/cajCodeLogo.png'; // Adjust the path as necessary

const HeaderWrapper = styled.header`
  background: #001f3f;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  height: 90px; // Adjust the height as necessary
  margin-right: 1rem;
  border-radius: 50%;
`;

const LogoText = styled.h1`
  color: #fff;
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1.2px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;

  a {
    color: #fff;
    margin-left: 1.5rem;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    transition: color 0.3s ease;

    &:hover {
      color: #ffd700; // Gold color on hover
    }

    @media (max-width: 768px) {
      margin-left: 1rem;
      font-size: 0.9rem;
    }

    @media (max-width: 480px) {
      margin-left: 0.5rem;
      font-size: 0.8rem;
    }
  }
`;

const Header = () => (
  <HeaderWrapper>
    <LogoWrapper>
      <LogoImage src={cajCodeLogo} alt="CajCode LLC Logo" />
      <LogoText>CajCode</LogoText>
    </LogoWrapper>
    <Nav>
      <a href="#services">Services</a>
      <a href="#contact">Contact</a>
    </Nav>
  </HeaderWrapper>
);

export default Header;
