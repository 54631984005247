import React from 'react';
import styled from 'styled-components';
import AppShowcase from './AppShowcase';

const testimonialsData = [
  {
    name: "FemFit Founder",
    text: "CajCode built an amazing app for FemFitNow. The process was seamless and the outcome exceeded my expectations.",
    app: "FemFitNow"
  },
  {
    name: "HairShop CEO",
    text: "The development of HairShop by CajCode was a game-changer for our business. The app is intuitive and our customers love it.",
    app: "HairShop"
  },
  {
    name: "FratSwipe Manager",
    text: "CajCode delivered an exceptional app that has transformed our service delivery. FratSwipe is now more engaging and efficient.",
    app: "FratSwipe"
  }
];

const TestimonialsWrapper = styled.section`
  padding: 80px 20px;
  background-color: #f9f9f9;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
`;

const TestimonialsTitle = styled.h2`
  font-size: 2.5em;
  margin-bottom: 50px;
  color: #001f3f;
`;

const TestimonialsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
`;

const Testimonial = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 30px;
  max-width: 350px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  }
`;

const TestimonialText = styled.div`
  color: #333;
`;

const TestimonialP = styled.p`
  font-size: 1.1em;
  margin-bottom: 15px;
`;

const TestimonialH4 = styled.h4`
  font-size: 1.2em;
  color: #001f3f;
  margin-top: 20px;
`;

const Testimonials = () => {
  return (
    <TestimonialsWrapper>
      <TestimonialsTitle>Testimonials</TestimonialsTitle>
      <TestimonialsContainer>
        {testimonialsData.map((testimonial, index) => (
          <Testimonial key={index}>
            <TestimonialText>
              <TestimonialP>"{testimonial.text}"</TestimonialP>
              <TestimonialH4>- {testimonial.name}, {testimonial.app}</TestimonialH4>
            </TestimonialText>
          </Testimonial>
        ))}
      </TestimonialsContainer>
    </TestimonialsWrapper>
  );
};

export default Testimonials;
