import './App.css';
import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Projects from './components/Projects';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Testimonials from './components/Testimonials';
import AppShowcase from './components/AppShowcase';


const App = () => (
  <>
    <Header />
    <Hero />
    <Projects />
    <AppShowcase />
    <Services />
    <Contact />
    <Testimonials />
    <Footer />
    
  </>
);

export default App;
