import React from 'react';
import styled, { keyframes } from 'styled-components';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

// Images for the projects
import iosApp from './images/iosApp.png';
import webApp from './images/webApp.png';
import softwareSolution from './images/softwareSolution.png';
import algorithmProduction from './images/algorithmProduction.png';
import aiManagement from './images/aiManagement.png';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ProjectsWrapper = styled.section`
  background: linear-gradient(135deg, #001f3f, #007bff);
  color: #fff;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ProjectTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
`;

const ProjectsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ProjectContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Project = styled.div`
  background: #fff;
  color: #333;
  padding: 1.5rem;
  margin: 1rem;
  border-radius: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 500px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const ProjectImage = styled.img`
  width: 200px;
  height: 200px;
  margin-bottom: 1rem;
`;

const ProjectDescription = styled.p`
  font-size: 1.2rem;
  margin: 0 1rem 1rem 1rem;
`;

const aiAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const AnimatedProject = styled(Project)`
  animation: ${aiAnimation} 3s infinite;
`;

const Dots = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  background: ${props => (props.active ? '#fff' : '#888')};
  border-radius: 50%;
  margin: 0 5px;
  transition: background 0.3s ease;
`;

const Projects = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <ProjectsWrapper id="projects">
      <ProjectTitle>Expertise</ProjectTitle>
      <ProjectsContainer>
        <ProjectContainer>
          <AutoPlaySwipeableViews index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
            <AnimatedProject>
              <ProjectImage src={iosApp} alt="iOS App Development" />
              <ProjectDescription>
                <strong>iOS App Development</strong><br />
                We create high-quality, user-friendly iOS applications tailored to your needs. Starting at budget-friendly prices.
              </ProjectDescription>
            </AnimatedProject>
            <AnimatedProject>
              <ProjectImage src={webApp} alt="Web Applications" />
              <ProjectDescription>
                <strong>Web Applications</strong><br />
                Our team develops robust and scalable web applications to enhance your online presence. Affordable pricing guaranteed.
              </ProjectDescription>
            </AnimatedProject>
            <AnimatedProject>
              <ProjectImage src={softwareSolution} alt="Software Database Solutions" />
              <ProjectDescription>
                <strong>Software Database Solutions</strong><br />
                We offer reliable software and database solutions to streamline your business operations. Cost-effective services.
              </ProjectDescription>
            </AnimatedProject>
            <AnimatedProject>
              <ProjectImage src={algorithmProduction} alt="Algorithm Production" />
              <ProjectDescription>
                <strong>Algorithm Production</strong><br />
                Our expertise in algorithm development ensures efficient and optimized solutions for your challenges. Competitive rates.
              </ProjectDescription>
            </AnimatedProject>
            <AnimatedProject>
              <ProjectImage src={aiManagement} alt="AI Management" />
              <ProjectDescription>
                <strong>AI Management</strong><br />
                Leverage our AI management services to integrate intelligent solutions into your business. Priced to fit your budget.
              </ProjectDescription>
            </AnimatedProject>
          </AutoPlaySwipeableViews>
        </ProjectContainer>
      </ProjectsContainer>
      <Dots>
        {[0, 1, 2, 3, 4].map((step) => (
          <Dot key={step} active={activeStep === step} />
        ))}
      </Dots>
    </ProjectsWrapper>
  );
};

export default Projects;
