import React from 'react';
import styled from 'styled-components';

// Enhance the overall container styling
const ServicesWrapper = styled.section`
  padding: 4rem 2rem;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const ServicesTitle = styled.h2`
  color: #001f3f;
  font-size: 3rem;
  margin-bottom: 2rem;
  font-weight: bold;
`;

const Service = styled.div`
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 350px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  h3 {
    color: #007bff;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }

  p {
    color: #333;
    font-size: 1rem;
    line-height: 1.5;
  }
`;

const Services = () => (
  <ServicesWrapper id="services">
    <ServicesTitle>Our Services</ServicesTitle>
    <Service>
      <h3>Custom Software Development</h3>
      <p>We develop applications tailored to your business needs.</p>
    </Service>
    <Service>
      <h3>Consulting</h3>
      <p>Our experts provide valuable insights and strategies for your projects.</p>
    </Service>
    <Service>
      <h3>Support & Maintenance</h3>
      <p>We offer ongoing support to ensure your software remains up-to-date.</p>
    </Service>
  </ServicesWrapper>
);

export default Services;
